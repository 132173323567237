:root {
  --bcl: #0e5f84;
  --blcl: #009ef3;
  --gcl: #0e842f;
  --glcl: #2df300;
  --rcl: #b60a0a;
  --rlcl: #ff2121;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-track-width: 42px;
}

body {
  margin: 0;
}

* {
  letter-spacing: normal !important;
}

.stdDialog {
  width: 600px;
}

.stdDialog mat-form-field {
  width: 100%;
}

.mat-mdc-expansion-panel:not([class*='mat-mdc-elevation-z']) {
  box-shadow: none !important;
}

.width100 {
  width: 100%;
}

.twoColGrid {
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-column-gap: 24px;
  width: 100%;
  align-items: flex-end;
}

.twoColGrid mat-form-field {
  width: 100%;
}

mat-pseudo-checkbox {
  display: none !important;
}

.twoColFlex {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  column-gap: 10px;

  & > * {
    width: 100%;
  }
}

.autoRowGrid {
  display: grid;
  grid-auto-rows: auto;
  width: 100%;
}

.autoRowGrid mat-form-field {
  width: 100%;
}

.matTabContainer {
  margin-top: 12px;
}

.autoColGrid {
  display: grid;
  grid-auto-columns: auto;
  width: 100%;
}

.autoColGrid mat-form-field {
  width: 100%;
}

.medium-icon {
  font-size: 18px !important;
  width: 18px !important;
  height: 18px !important;
}

.log-btn {
  cursor: pointer;
  margin: 2px 0 0 3px;
}

.table-cell {
  font-size: 14px !important;
  padding: 0 8px !important;
}

.arrow-up {
  top: -7px;
  left: 20px;
}

.arrow-down {
  top: 5px;
  left: -1px;
}

.captionWithButton {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.captionWithButton button {
  margin-top: -5px;
}

.mat-mdc-dialog-actions {
  column-gap: 10px;
  justify-content: flex-start !important;
  flex-direction: row-reverse;
}

.mt10 {
  margin-top: 10px !important;
}

.p0 {
  padding: 0 !important;
}

.mlr2 {
  margin-left: 2px !important;
  margin-right: 2px !important;
}

.logoPassOffice {
  height: 40px;
  max-width: 180px;
  margin: auto 20px auto 0;
  justify-self: flex-end;
  display: flex;
}

.logoPassOffice .gpass {
  height: 25px;
}

.logoPassOffice-gpass {
  height: 25px;
  margin: auto 20px auto 0;
  justify-self: flex-end;
}

.m0 {
  margin: 0;
}

.none {
  display: none;
}

.without-padding > .mat-dialog-container {
  padding: 0;
}

.Root-dialog-container .mat-dialog-container,
.Car-dialog-container .mat-dialog-container,
.AcsBaseConfig-dialog-container .mat-dialog-container,
.Pass-dialog-container .mat-dialog-container,
.Settings-dialog-container .mat-dialog-container {
  padding: 0;
}

.PersonCategory-dialog-container .mat-dialog-content,
.Site-dialog-container .mat-dialog-content,
.AccessGroup-dialog-container .mat-dialog-content {
  overflow: unset;
}

.hidden {
  display: none !important;
}

.dark {
  background: #424242;
  color: #ffffff;
}

.cdk-overlay-container {
  width: 100vw;
}

/*** MDC COMPONENTS CUSTOM STYLES ***/

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background: none;
}

.mat-mdc-chip.mdc-evolution-chip--with-trailing-action
  .mat-mdc-chip-action-label {
  display: flex;
  align-items: center;
  column-gap: 10px;
}

.mdc-evolution-chip-set__chips {
  align-items: center;
  margin-left: 0 !important;
}

.mat-mdc-form-field-infix {
  padding-bottom: 2px !important;
  min-height: 50px !important;
}

.mdc-text-field {
  align-items: center !important;
}

.mat-mdc-list-item .mdc-list-item__primary-text {
  font: 400 14px / 24px Roboto, 'Helvetica Neue', sans-serif;
}

.mdc-switch__icons {
  display: none;
}

// toggle size
.mdc-switch {
  transform: scale(0.7);
}

// padding in chip input (text, buttons)
.mdc-evolution-chip__text-label {
  align-items: center;
  display: flex;
  column-gap: 5px;
  word-break: break-word !important;
  white-space: break-spaces !important;
}

// paginator select btn
.mat-mdc-paginator .mat-mdc-form-field-flex {
  height: 38px;
}

// table column name size
.mat-mdc-table .mat-mdc-header-cell {
  font-size: 12px !important;
  line-height: 16px;
  color: #999999;
}

.mdc-list-item__end {
  display: none;
}

.mdc-text-field {
  padding-left: 8px !important;
}

.mat-mdc-form-field-subscript-wrapper {
  height: 20px;
}

.mat-mdc-form-field-error-wrapper {
  padding: 0 8px !important;
}

.mat-mdc-form-field-icon-suffix {
  align-self: flex-end !important;
}

.mat-mdc-chip-row {
  min-height: var(--mdc-chip-container-height, 32px) !important;
  height: auto !important;
}

.mat-mdc-select-arrow-wrapper {
  display: none !important;
}

.mdc-evolution-chip-set .mdc-evolution-chip {
  margin-left: 0 !important;
}

//Стрелка открытия селекта у пагинатора
.mat-mdc-paginator
  .mat-mdc-select
  > .mat-mdc-select-trigger
  > .mat-mdc-select-arrow-wrapper {
  display: flex !important;
}

// Выпадающий список селекта
.mdc-menu-surface.mat-mdc-select-panel {
  padding: 0 !important;
}

// Option селекта
.mat-mdc-option {
  min-height: 38px !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled)
  .mdc-line-ripple::before {
  border-bottom-color: rgba(0, 0, 0, 0.3);
}

.notify-snackbar {
  position: absolute !important;
  top: 62px !important;
  right: 4px !important;
  bottom: auto !important;
  left: auto !important;
}

@media (max-width: 480px), (max-width: 344px) {
  .mat-mdc-snack-bar-container {
    width: fit-content !important;
  }
}

.mdc-dialog__title::before {
  display: flex !important;
  height: 5px !important;
}

.mdc-dialog__title {
  margin: 0 !important;
  padding: 4px 24px 0 !important;
}

.mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-error-wrapper {
  padding: 0 6px !important;
}

.fill-primary-color {
  fill: var(--po-primary-500);
}
